<template>
  <div id="app">
   
      <router-view v-if="showPage == 1" name="m"></router-view>
      <router-view v-if="showPage == 2" name="pc"></router-view>
    
  </div>
</template>
<!-- <link rel="stylesheet" href="https://unpkg.com/muse-ui/dist/muse-ui.css"> -->
<script>
export default {
  name: "app",
  data() {
    return {
      showPage: "",
    };
  },
  components: {},
  created() {
    if (this._isMobile()) {
      this.showPage = 1;
    } else {
      this.showPage = 2;
    }
    console.log(this.showPage);
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  #app{ 
    cursor:pointer;

  }
}
</style>
