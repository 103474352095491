import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    meta: {
      keepAlive: true, 
    },
    components: {
      default: () => import('@/views/pc/IndexView'),
      pc: () => import('@/views/pc/IndexView'),
      m: () => import('@/views/move/IndexView'),


    },

  }, {
    path: '/loan',
    meta: {
      keepAlive: true, 
    },
    components: {
      pc: () => import('@/views/pc/FindaLoanView')

    }
  }, {
    path: '/Strategy',
    meta: {
      keepAlive: true, 
    },
    components: {
      pc: () => import('@/views/pc/StrategyView')

    }
  } ,{
    path: '/Interlocution',
    meta: {
      keepAlive: true, 
    },
    components: {
      pc: () => import('@/views/pc/InterlocutionView')

    }
  },{
    path: '/We',
    meta: {
      keepAlive: true, 
    },
    components: {
      pc: () => import('@/views/pc/WeView')

    }
  },
  {
    path: '/APP',
    name:'APP',
    components: {
      pc: () => import('@/views/pc/ApplicationView'),
      meta:{ 
        hideNav:true,
      }

    }
  }, {
    path: '/Join',
    meta: {
      keepAlive: true, 
    },
    components: {
      pc: () => import('@/views/pc/JoinView')

    }
  }, {
    path: '/jsq',
    meta: {
      keepAlive: true, 
    },
    components: {
      pc: () => import('@/views/pc/ComputeView')

    }
  },
  {
    path: '/financialnews',
    name:'financialnews',
   
    components: {
      pc: () => import('@/views/pc/FinancialNews'),
      meta:{ 
        hideNav:true,
        title: "financialnews",
        show: true 
      }
    }
  },
  {
    path: '/products',
    components: {
      m: () => import('@/views/move/productsView')

    }
  },{
    path: '/m-we',
    components: {
      m: () => import('@/views/move/WormView')

    }
  },
  {
    path: '/m-js',
    components: {
      m: () => import('@/views/move/ComputeView')

    }
  },
  {
    path: '/m-tj',
    components: {
      m: () => import('@/views/move/ConditionView')

    }
  }, {
    path: '/m-news',
    components: {
      m: () => import('@/views/move/NewsView')

    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


